import { ModalBase } from "./modal_base";
import { ListSettingsModal } from "./list/list_settings_modal";
import { ListResolveModal } from "./list/list_resolve_modal";
import { ListSignOffModal } from "./list/list_signoff_modal";
import { ListDeleteModal } from "./list/list_delete_modal";
import { TcoMisMilCommentAdd } from "./tacho/tco_mis_mil_comment_add";
import { TcoMisMilCommentEdit } from "./tacho/tco_mis_mil_comment_edit";
import { TcoMisMilCommentDelete } from "./tacho/tco_mis_mil_comment_delete";
import { MsgInboxModal } from "./msg_inbox";
import { TcoMisMilCommentAddBulkNonePaginated } from "./tacho/tco_mis_mil_comment_add_bulk_none_paginated";

// TODO: look to convert the list modals list remodal methods to be internal in js files to reduce the
// TODO: need for the ModalConfirm event in the list.js
window.addEventListener('ModalCoreSetup', (e) => {
    if(!app.OPTIONS.list) {
        return;
    }

    if(app.OPTIONS.delete) {
        new ModalBase('list_hide_modal');
        new ModalBase('list_unlink_modal');
        new ListDeleteModal();
    }

    if(app.OPTIONS.new_list_structure) {
        new ListSettingsModal();
    }

    if(app.URI.length >= 3) {
        switch (app.URI[2]) {
            case 'veh_def':
            case 'mai_ans':
            case 'veh_def_ajax':
            case 'mai_ans_ajax':
                new ModalBase('list_email_defect_modal');
                new ListResolveModal();
                break;
            case 'mai':
            case 'mai_ajax':
                if(parseInt(app.CACHE.USER.user_type_id) >= parseInt(app.CACHE.USER_TYPES.OPADMIN))
                    new ListSignOffModal();
                break;
            case 'rep_che':
            case 'rep_che_ajax':
                new ModalBase('list_assign_modal');
                new ModalBase('list_unassign_modal');
                break;
            case 'tco_mis_mil':
            case 'tco_mis_mil_ajax':
                new TcoMisMilCommentAddBulkNonePaginated();
                new TcoMisMilCommentAdd();
                new TcoMisMilCommentEdit();
                new TcoMisMilCommentDelete();
                break;
            case 'msg_inbox':
                new MsgInboxModal();
                break;
        }
    }

    if(app.OPTIONS.saved_filters) {
        new ModalBase('list_filter_delete_modal');
        new ModalBase('list_filter_save_modal');
    }

    if(app.OPTIONS.copy || (
        typeof app.URI[2] !== "undefined" &&
        (app.URI[2] === 'rep_che' || app.URI[2] === 'msg') &&
        app.CACHE.USER.user_type_id !== app.CACHE.USER_TYPES.RESELLER)
    ) {
        new ModalBase('list_copy_modal');
    }
});